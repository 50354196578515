<script lang="ts" setup>
import type { BreadcrumbLink } from "#ui/types";
import type { CategoryPageRecord, PageHeaderRecord, PageRecord, SeriesPageRecord, SolutionPageRecord } from "~~/server/graphql/datocms/generated";

const { data, currentPage } = defineProps<{
  data: PageHeaderRecord;
  currentPage: PageRecord | CategoryPageRecord | SeriesPageRecord | SolutionPageRecord;
}>();

const { t } = useI18n();

const breadcrumbs = [] as Array<BreadcrumbLink>;

if (currentPage) {
  // if (pageType) {
  //   const { data: layoutData } = await useFetch("/api/layout", { query: { locale: locale } });

  //   let parentPage: PageRecord | null | undefined = null;

  //   switch (pageType) {
  //     case "series":
  //       parentPage = layoutData.value?.parentPageSeries as PageRecord;
  //       break;
  //     case "solution":
  //       parentPage = layoutData.value?.parentPageSolution as PageRecord;
  //       break;
  //   }

  //   if (parentPage) {
  //     breadcrumbs.push({
  //       label: parentPage?.title,
  //       to: localePath(`/${parentPage?.slug}`),
  //     });
  //   }
  // }

  // if (page.parentPage) {
  //   breadcrumbs.push({
  //     label: page.parentPage?.title,
  //     to: localePath(`/${page.parentPage?.slug}`),
  //   });
  // }

  if (currentPage.title !== t("page.title.home")) {
    breadcrumbs.push({ label: currentPage.title });
  }
}
</script>

<template>
  <PageHeader
    :breadcrumbs="breadcrumbs"
    :title="data.title"
    :opt-intro="data.optIntro ?? undefined"
    :opt-image="data.optImage ?? undefined"
    :cta-list="data.ctaList"
    :color-mode="data.colorMode"
  />
</template>
